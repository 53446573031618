import { graphql, PageProps } from "gatsby"
import React from "react"
import BaseComponent from "../components/page/base"
import { TemplateErvaringQueryQuery } from "../__generated__/graphql-gatsby"

type IProps = PageProps<TemplateErvaringQueryQuery>

const ErvaringPage: React.FC<IProps> = ({ data: { wpErvaring } }) => {
  const key = String(wpErvaring?.uri)

  return <BaseComponent data={wpErvaring} sessionKey={key} />
}

export default ErvaringPage

export const query = graphql`
  fragment PageBuilderErvaringCTA on WpErvaring_PageBuilder_PageBuilder_Cta {
    __typename
    titel
    subtitel
    uitlijningTitel
    linkKnop {
      title
      url
      target
    }
    uitlijningKnop
  }
  fragment PageBuilderErvaringImage on WpErvaring_PageBuilder_PageBuilder_Afbeelding {
    __typename
    maxWidth
    afbeelding {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
  fragment PageBuilderErvaringText on WpErvaring_PageBuilder_PageBuilder_TekstEditor {
    __typename
    instellingen {
      achtergrond
      breedte
    }
    tekst
  }
  fragment PageBuilderErvaringSpacer on WpErvaring_PageBuilder_PageBuilder_Spacer {
    __typename
    hoogte
  }
  fragment PageBuilderErvaringFaq on WpErvaring_PageBuilder_PageBuilder_Faq {
    __typename
    titel
    tekst
    kleur
    standaardGesloten
    metaData
  }
  fragment PageBuilderErvaringUitgelichteActiviteit on WpErvaring_PageBuilder_PageBuilder_UitgelichteActiviteit {
    __typename
    flip
    onlyImg
    activiteit {
      ...UitgelichteActiviteit
    }
  }
  fragment PageBuilderErvaringUitgelichteErvaring on WpErvaring_PageBuilder_PageBuilder_UitgelichteErvaring {
    __typename
    flip
    onlyImg
    ervaring {
      ...UitgelichteErvaring
    }
  }
  fragment PageBuilderErvaringLaatsteBerichten on WpErvaring_PageBuilder_PageBuilder_LaatsteBerichten {
    __typename
    type {
      nodesOfPostTypes {
        nodes(limit: 5) {
          ...LaatsteBerichtenData
        }
      }
    }
    aantal
  }
  fragment PageBuilderErvaringAgenda on WpErvaring_PageBuilder_PageBuilder_Agenda {
    __typename
    weergave
    tags {
      id
    }
  }
  fragment PageBuilderErvaringForm on WpErvaring_PageBuilder_PageBuilder_Formulier {
    __typename
    nieuwsbriefFormulier
    addtext
    text
    redirect {
      url
    }
    form {
      ...Form
    }
  }
  fragment PageBuilderErvaringOverzicht on WpErvaring_PageBuilder_PageBuilder_OverzichtPaginas {
    __typename
    format
    sortering
    tags {
      id
    }
  }
  fragment PageBuilderErvaringYoutube on WpErvaring_PageBuilder_PageBuilder_Youtube {
    __typename
    titel
    toonTitel
    youtubeId
    aspectRatio
    tekst
    metTekst {
      plaatsTekst
      achtergrond
      breedteContainer
    }
  }
  fragment PageBuilderErvaringFacebook on WpErvaring_PageBuilder_PageBuilder_Facebook {
    __typename
    tabs
  }
  fragment PageBuilderErvaringButtonsGroup on WpErvaring_PageBuilder_PageBuilder_ButtonsGroup {
    __typename
    buttons {
      button {
        url
        title
        target
      }
    }
  }
  fragment PageBuilderErvaringBlocks on WpErvaring_PageBuilder_PageBuilder {
    ... on WpErvaring_PageBuilder_PageBuilder_TekstEditor {
      ...PageBuilderErvaringText
    }
    ... on WpErvaring_PageBuilder_PageBuilder_Spacer {
      ...PageBuilderErvaringSpacer
    }
    ... on WpErvaring_PageBuilder_PageBuilder_Afbeelding {
      ...PageBuilderErvaringImage
    }
    ... on WpErvaring_PageBuilder_PageBuilder_Faq {
      ...PageBuilderErvaringFaq
    }
    ... on WpErvaring_PageBuilder_PageBuilder_Cta {
      ...PageBuilderErvaringCTA
    }
    ... on WpErvaring_PageBuilder_PageBuilder_UitgelichteActiviteit {
      ...PageBuilderErvaringUitgelichteActiviteit
    }
    ... on WpErvaring_PageBuilder_PageBuilder_UitgelichteErvaring {
      ...PageBuilderErvaringUitgelichteErvaring
    }
    ... on WpErvaring_PageBuilder_PageBuilder_LaatsteBerichten {
      ...PageBuilderErvaringLaatsteBerichten
    }
    ... on WpErvaring_PageBuilder_PageBuilder_OverzichtPaginas {
      ...PageBuilderErvaringOverzicht
    }
    ... on WpErvaring_PageBuilder_PageBuilder_Agenda {
      ...PageBuilderErvaringAgenda
    }
    ... on WpErvaring_PageBuilder_PageBuilder_Formulier {
      ...PageBuilderErvaringForm
    }
    ... on WpErvaring_PageBuilder_PageBuilder_Youtube {
      ...PageBuilderErvaringYoutube
    }
    ... on WpErvaring_PageBuilder_PageBuilder_Facebook {
      ...PageBuilderErvaringFacebook
    }
    ... on WpErvaring_PageBuilder_PageBuilder_ButtonsGroup {
      ...PageBuilderErvaringButtonsGroup
    }
  }
  fragment PageBuilderErvaring on WpErvaring_PageBuilder {
    pageBuilder {
      ...PageBuilderErvaringBlocks
    }
  }
  fragment wpErvaring on WpErvaring {
    id
    uri
    seo {
      ...SEO
    }
    page_builder {
      ...PageBuilderErvaring
    }
  }
  query TemplateErvaringQuery($id: String!) {
    wpErvaring(id: { eq: $id }) {
      ...wpErvaring
    }
  }
`
